import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/aaron/Apps/fresh-pets/src/components/mdx-layout/mdx-layout.js";
import SEO from '../components/seo/seo.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title='404 Not Found' description='You just hit a route that doesn&rsquo;t exist... the sadness.' keywords={['Fresh', 'Pets', '404']} mdxType="SEO" />
    <h1>{`Not Found`}</h1>
    <h2>{`You just hit a route that doesn't exist... the sadness.`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      